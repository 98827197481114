import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight, oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Strings } from '../../utils/Strings'
import CommonButton from '../CommonButton/CommonButton';
import { color } from '../../utils/Colors';
import { Padding } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMyContext } from '../CommanToasterContext/toast';
import { useGetRSAkeyMutation, useGetUserActionStatusMutation } from '../../services/proxyServerQuery';
import { isSnowFlake } from '../../utils/utils';
import { SqlCommandSection } from '../CommanOAuth/BeforeSignUp.styles';
const customStyle = {
    'pre[class*="language-"]': {
        ...oneDark['pre[class*="language-"]'],
        background: 'transparent', // Set background to transparent
        padding: '0px 1rem !important', // Set padding
    },
    'code[class*="language-"]': {
        color: '#008000',
        fontFamily: 'Helvetica',
        fontSize: '1em',
        lineHeight: '1.5em',
    },
};

const generatePassword = () => {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let newPassword = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        newPassword += charset[randomIndex];
    }
    return newPassword;
};


const SnowflakeStep = ({ setStep }) => {

    const [getConsumerAction, { data: statusActionData, isLoading: statusLoading, error: statusError }] = useGetUserActionStatusMutation();
    const [getRSAKey, { data: RSAData, isLoading: RSADataLoading, error: RSADataError }] = useGetRSAkeyMutation();
    const { setOpen2, setMessage2 } = useMyContext();
    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [copied3, setCopied3] = useState(false);
    const [copied4, setCopied4] = useState(false);
    const [RSAkey, setRSAkey] = useState("")
    const password = generatePassword();

    useEffect(() => {
        if (RSAData !== undefined) {
            if (RSAData?.statusCode === 200) {
                setRSAkey(RSAData?.response?.public_key)
                return
            } else if (RSAData?.statusCode !== 200) {
                setOpen2(true)
                setMessage2(statusActionData?.statusMessage)
                return
            }
        }
    }, [RSAData])

    useEffect(() => {
        if (statusActionData !== undefined) {
            if (statusActionData?.statusCode === 200) {
                setStep(7);
                return
            } else if (statusActionData?.statusCode !== 200) {
                setOpen2(true)
                setMessage2(statusActionData?.statusMessage)
                return
            }
        }
        else if (statusError) {
            console.log('statusError', statusError);
            setOpen2(true)
            setMessage2("Action Status Not Found")
            return
        }
    }, [statusActionData, statusError])

    useEffect(() => {
        if (isSnowFlake())
            // RSAID : "24e666d2-ca89-4ff9-8194-cca02d854afc/"
            getRSAKey()
    }, [])


    useEffect(() => {
        const timer = setTimeout(() => {
            setCopied1(false)
            setCopied2(false)
            setCopied3(false)
            setCopied4(false)
        }, 4000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [copied1, copied2, copied3, copied4]);   




    const codeString1 =
`USE ROLE ACCOUNTADMIN;

SET warehouse_name = 'MAD_CONNECT_WH'; 

CREATE OR REPLACE USER MADCONNECTUSER
LOGIN_NAME = 'MADCONNECTUSER'
DEFAULT_WAREHOUSE = $warehouse_name
TYPE = SERVICE;

ALTER USER MADCONNECTUSER SET RSA_PUBLIC_KEY= '${RSAkey}';`;

    const codeString2 = 
`CREATE OR REPLACE role mad_tech_customer_role;
GRANT USAGE ON WAREHOUSE MAD_CONNECT_WH TO ROLE mad_tech_customer_role;
GRANT APPLICATION ROLE MADCONNECT.mad_connect_app_user_role TO ROLE mad_tech_customer_role;
GRANT ROLE mad_tech_customer_role TO USER MADCONNECTUSER;
    `;

    return (
        <Box sx={{ maxWidth: "93.75rem", margin: "auto" }}>
            <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#fff", width: "100%", alignItems: "center" }}>
                <Box sx={{ mt: "1.5rem", display: "flex", flexDirection: "column", width: "100%", alignItems: "center", }}>
                    <Box>
                        <p className="text-center mt-2" style={{ fontSize: "2rem", fontWeight: 700, fontFamily: "Helvetica Neue", }} >   {Strings.SnowTitle} </p>
                        <p className="text-center" style={{ marginTop: -10, color: "#999999", fontSize: "1.25rem", fontWeight: 500, fontFamily: "Helvetica Neue", }}>  {Strings.SnowSubTitle}</p>
                    </Box>

                </Box>
                <Box sx={{ maxHeight: "590px", overflowY: "auto", width: "90%", }}>
                    <Box sx={{ mt: "2rem", width: "97%", }}>
                       <Typography sx={{ mb: 1, textAlign: "left", color: "#000000", fontSize: "1.5rem", fontWeight: 500, fontFamily: "Helvetica Neue" }}>
                            Step1: Set Warehouse and Create Service User
                        </Typography>
                        <Box 
                            sx={{ 
                                marginBottom: 4,
                                padding: "0px",
                                position: 'relative', 
                                width: "100%", // Ensure the Paper takes the full width of the Box
                            }}
                        >
                            <CopyToClipboard text={codeString1} onCopy={() => setCopied1(true)}>
                                <Button variant="contained" size="small" sx={{ position: 'absolute', top: 28, right: 22, background: "grey", minWidth: "40px" }}>
                                    {copied1 ? <CheckCircleIcon /> : <ContentCopyIcon />}
                                </Button>
                            </CopyToClipboard>
                            <SqlCommandSection language="sql" style={customStyle}>
                                {codeString1}
                            </SqlCommandSection>
                        </Box>
                        <Typography sx={{ mb: 1, textAlign: "left", color: "#000000", fontSize: "1.5rem", fontWeight: 500, fontFamily: "Helvetica Neue" }}>
                            Step2: Create Role to attach to user MADCONNECTUSER
                        </Typography>
                        <Box 
                            sx={{ 
                                marginBottom: 4,
                                padding: "0px",
                                position: 'relative', 
                                width: "100%", // Ensure the Paper takes the full width of the Box
                            }}
                        >
                            <CopyToClipboard text={codeString2} onCopy={() => setCopied2(true)}>
                                <Button variant="contained" size="small" sx={{ position: 'absolute', top: 28, right: 22, background: "grey", minWidth: "40px" }}>
                                    {copied2 ? <CheckCircleIcon /> : <ContentCopyIcon />}
                                </Button>
                            </CopyToClipboard>
                            <SqlCommandSection language="sql" style={customStyle}>
                                {codeString2}
                            </SqlCommandSection>
                        </Box> 
                    </Box>
                </Box>
                <Box sx={{ width: "90%", my: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", }} >
                    <CommonButton
                        width={160}
                        height={"2.75rem"}
                        textStyle={{
                            fontSize: "18px",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            getConsumerAction()
                            //success
                            // getConsumerAction("bcd1bb10-b387-4d5f-8444-18fe43b57d53/")
                            // fail
                            // getConsumerAction("4b964ca3-9931-44c5-a53a-32a0476a34f4/")
                        }}
                        title={"Verify"}
                        color={color.buttonColor
                        }
                        isLoading={statusLoading}
                    ></CommonButton>
                </Box>
            </Box>
        </Box>
    );
};

export default SnowflakeStep;