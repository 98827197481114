// export const BASE_URL = 'http://localhost:8092/v1'  // local server
// export const BASE_URL = 'http://ec2-44-213-15-199.compute-1.amazonaws.com/v1' // dev/demo server
// export const BASE_URL = 'http://ec2-54-209-203-102.compute-1.amazonaws.com/v1' // prod server
// export const BASE_URL = 'https://madconnect.io/v1' // prod server
// export const HOST = 'http://localhost:8092/v1'  // local server

// export const HOST = "http://ec2-44-213-15-199.compute-1.amazonaws.com"

export const BASE_URL = window.location.origin + '/v1'
export const HOST = window.location.origin
