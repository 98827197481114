import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";
import whiteLogo from "../../assets/madconnect-white.svg";
import "./signup.css";
import {
    Box,
    Grid,
    Paper,
    Typography,
    useMediaQuery,
    Button,
} from "@mui/material";
import { color } from "../../utils/Colors";
import madConnectLogo from '../../assets/madconnectlight 2 (1).svg'
import { madTechState } from "../../features/madTechSlice";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "../AuthLayout/AuthLayout";
import useDocumentTitle from "../../hook/useDocumentTitle";
import { useMyContext } from "../CommanToasterContext/toast";
import CopyToClipboard from "react-copy-to-clipboard"; 
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CommonButton from "../CommonButton/CommonButton";
import { LeftContainer, LeftImage, LoginHeading, LoginPara, LoginSigninContainer, WelcomeToHeading } from "./CommanSignIn.styles";
import { useGetIntegrationStatusMutation } from "../../services/proxyServerQuery";
import { SqlCommandSection } from "./BeforeSignUp.styles";

const customStyle = {
    'pre[class*="language-"]': {
        ...oneDark['pre[class*="language-"]'],
        background: 'transparent',
        padding: '0px 1rem !important',
        marginBottom: "0px",
        fontSize: '14px',
    },
    'code[class*="language-"]': {
        color: '#008000',
        fontFamily: 'Helvetica',
        fontSize: '14px',
        lineHeight: '1.5em',
    },
};

function BeforeSignup() {
    const OAuthType = true;
    const background = "transparent";
    const ContentFontSize = "16px"
    const TextFontSize = "32px"
    const [getIntegrationStatus, { data: statusData, error: statusError, isSuccess, isLoading }] = useGetIntegrationStatusMutation({
        fixedCacheKey: "verify"
    });
    const { setOpen2, setMessage2 } = useMyContext();

    useDocumentTitle("Sign up");
    const matchesxsss = useMediaQuery("(min-width:495px)");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get('clientId');

    // useEffect(() => {
    //     if (OAuthType === true && clientId === null) {
    //         window.close();
    //     }
    // }, [OAuthType]);

    useEffect(() => {
        if (statusData) {
            if (statusData?.statusCode === 200) {
                window.location.href = "/signup";
            } else if (statusData?.statusCode !== 200) {
                setOpen2(true);
                setMessage2(statusData?.statusMessage);
            }
        }
    }, [statusData, statusError])


    const { userChoice, setUser } = useSelector(madTechState);
    const captchaRef = useRef(null);

    const [userType, setUserType] = useState(userChoice);
    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCopied1(false)
            setCopied2(false)
        }, 4000);

        return () => clearTimeout(timer);
    }, [copied1, copied2]);

    const dispatch = useDispatch();
    const navigate = useNavigate();



    const codeString1 =
`USE ROLE ACCOUNTADMIN;
CREATE WAREHOUSE IF NOT EXISTS MAD_CONNECT_WH;
USE WAREHOUSE MAD_CONNECT_WH;
CREATE or REPLACE DATABASE MAD_CONNECT_CONSUMER_DB;
CREATE or REPLACE SCHEMA MAD_CONNECT_CONSUMER_DB.CONSUMER_SCHEMA;

USE DATABASE MAD_CONNECT_CONSUMER_DB;
USE SCHEMA CONSUMER_SCHEMA;
CREATE NETWORK RULE network_rule_backend_service
TYPE = 'HOST_PORT'
MODE= 'EGRESS'
VALUE_LIST = ('0.0.0.0:443','0.0.0.0:80');

CREATE or replace EXTERNAL ACCESS INTEGRATION madconnect_integration
ALLOWED_NETWORK_RULES = (network_rule_backend_service)
ALLOWED_AUTHENTICATION_SECRETS = all
ENABLED = true;

GRANT USAGE ON INTEGRATION madconnect_integration TO APPLICATION MADCONNECT;
GRANT USAGE ON WAREHOUSE mad_connect_wh TO application MADCONNECT;

CALL MADCONNECT.MAD_CONNECT_VERSIONED_SCHEMA.SET_EXTERNAL_INTEGRATION(PARSE_JSON('{
"external_access_integration_name": "madconnect_integration"}'));
`;


    return (
        <AuthLayout OAuthType={OAuthType}>
            <Grid container bgcolor={background}>
                {/* Company Details Section */}
                <Grid item md={7} lg={7} sm={12} xs={12} position='relative'>
                    <LeftImage
                        alt={'MADCONNECT'}
                        src={whiteLogo}
                        onClick={() => navigate('/')}
                    />
                    <LoginSigninContainer>
                        <LeftContainer matchesxsss={matchesxsss}>
                            <div className={`${matchesxsss ? 'row' : 'new-row'}`}>

                                <div className='col-12' style={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <div className={`${matchesxsss ? 'row' : 'new-row'}`}>
                                        <div className='col-12'>
                                            <WelcomeToHeading>Welcome to</WelcomeToHeading>
                                        </div>
                                    </div>
                                    <div className={`${matchesxsss ? 'row' : 'new-row'}`}>
                                        <div className='col-12'>
                                            <img style={{ height: 'auto', width: "15rem" }} alt={'MADCONNECT'} src={madConnectLogo}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LeftContainer>
                    </LoginSigninContainer>
                </Grid>

                {/* Description Section */}
                <Grid item md={5} lg={5} sm={12} xs={12} bgcolor={background} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '98vh', // Ensure the Grid item takes the full height of the viewport or parent container
                }}>

                    <Box sx={{ mt: "2rem", width: "80%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}
                    >
                        <LoginHeading className='text-center mb-2' TextFontSize={TextFontSize}>
                            Network access setup
                        </LoginHeading>
                        <LoginPara className='text-center mb-4 sub-text-color' ContentFontSize={ContentFontSize}>
                            Please execute the following commands in a worksheet using the account admin role. The commands create a warehouse and enable MadConnect connectors to access external platforms to send/receive data.</LoginPara>
                        <Box 
                            sx={{ 
                                marginBottom: 4,
                                padding: "0px",
                                position: 'relative', 
                                width: "100%", // Ensure the Paper takes the full width of the Box
                            }}
                        >
                            <CopyToClipboard text={codeString1} onCopy={() => setCopied1(true)}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ position: 'absolute', top: 28, right: 22, background: "grey", minWidth: "40px" }}
                                >
                                    {copied1 ? <CheckCircleIcon /> : <ContentCopyIcon />}
                                </Button>
                            </CopyToClipboard>
                            <SqlCommandSection language="sql" style={oneLight}>
                                {codeString1}
                            </SqlCommandSection>
                        </Box>
                        <Typography
                            sx={{
                                mb: 1,
                                textAlign: "center", // Center text horizontally
                                color: "#000000",
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                fontFamily: "Helvetica Neue",
                            }}
                        >

                        </Typography>

                        <CommonButton
                            width={160}
                            height={"2.75rem"}
                            textStyle={{
                                fontSize: "18px",
                                fontWeight: "500",
                            }}
                            onClick={() => {
                                // localStorage.setItem("integrationId", "37d99f9b-e32a-420d-975d-a8a882cea9fe/");
                                // S: "37d99f9b-e32a-420d-975d-a8a882cea9fe/"
                                getIntegrationStatus()
                                // F: "079467f3-aaf8-4946-a909-3bd2895eddc4/"
                                // getIntegrationStatus("079467f3-aaf8-4946-a909-3bd2895eddc4/")
                            }}
                            title={"Verify"}
                            color={color.buttonColor}
                            isLoading={isLoading}
                        ></CommonButton>
                    </Box>

                </Grid>
            </Grid>
        </AuthLayout>
    );
}

export default BeforeSignup;